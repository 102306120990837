<template>
  <div>
    <div class="card shadow">
      <div class="p-4" @keyup.enter="$emit('getEsportazioniList')">
        <div class="row">
          <div class="col-md-3">
            <SelectInput
              :options="richieste_tipologie_esportazioni"
              name="statoEsportazioniLookup"
              placeholder="Tipologia"
              :value="idTipologiaEsportazioni"
              @changeSelect="setTipologiaEsportazioni($event)"
            />
          </div>
          <div class="col-md-3">
            <Datepicker
              v-model="dataEsportazioneDaSelected"
              @update:modelValue="setDataEsportazioneDaSelected($event)"
              placeholder="Data esportazione da"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
            />
          </div>
          <div class="col-md-3">
            <Datepicker
              v-model="dataEsportazioneASelected"
              @update:modelValue="setDataEsportazioneASelected($event)"
              placeholder="Data esportazione a"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
            />
          </div>
          <div class="text-end col-md-3">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getEsportazioniList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watch, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";

export default defineComponent({
  name: "filtri-esportazioni",
  emits: ["resetFilters", "getEsportazioniList"],
  components: {
    SelectInput,
    Datepicker,
  },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const richieste_tipologie_esportazioni = computed(() =>
      store.getters.getStateFromName("richieste_tipologie_esportazioni")
    );
    const keys = ref("rtl");
    if (!richieste_tipologie_esportazioni.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const idTipologiaEsportazioni = computed(
      () => store.getters.idTipologiaEsportazioni
    );
    const setTipologiaEsportazioni = (event) => {
      store.commit("setTipologiaEsportazioni", event);
    };

    const dataEsportazioneDaSelected = computed(
      () => store.getters.dataEsportazioneDaSelected
    );
    const setDataEsportazioneDaSelected = (event) => {
      store.commit("setDataEsportazioneDaSelected", event);
    };

    const dataEsportazioneASelected = computed(
      () => store.getters.dataEsportazioneASelected
    );
    const setDataEsportazioneASelected = (event) => {
      store.commit("setDataEsportazioneASelected", event);
    };

    return {
      idTipologiaEsportazioni,
      dataEsportazioneASelected,
      dataEsportazioneDaSelected,
      setTipologiaEsportazioni,
      setDataEsportazioneDaSelected,
      setDataEsportazioneASelected,
      richieste_tipologie_esportazioni,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedesportazioni_list")
      ),
    };
  },
});
</script>

<template>
  <div>
    <FiltriEsportazioni
      @getEsportazioniList="searchEsportazioniList"
      @resetFilters="resetFilters"
    />
    <TableEsportazioni
      @getEsportazioniList="getEsportazioniList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
  onUnmounted,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriEsportazioni from "@/components/components-fit/esportazioni/FiltriEsportazioni.vue";
import TableEsportazioni from "@/components/components-fit/esportazioni/TableEsportazioni.vue";
import { getList } from "@/requests/esportazioniRequests";

export default defineComponent({
  name: "Esportazioni",
  components: {
    FiltriEsportazioni,
    TableEsportazioni,
  },
  props: {
    campiLocked: {
      type: Array,
      required: false,
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Esportazioni");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const id_tipologia_richiesta = computed(
      () => store.getters.idTipologiaEsportazioni
    );
    const data_esportazione_da = computed(
      () => store.getters.dataEsportazioneDaSelected
    );
    const data_esportazione_a = computed(
      () => store.getters.dataEsportazioneASelected
    );
    const rowsToSkip = computed(() => store.getters.rowsToSkipEsportazioni);
    const fetchRows = computed(() => store.getters.fetchRowsEsportazioni);
    /* const sortColumn = computed(() => store.getters.sortColumnEsportazioni);
    const sortOrder = computed(() => store.getters.sortOrderEsportazioni); */

    const getEsportazioniList = async () => {
      await store.dispatch("setStoreListData", {
        keys: {
          id_Tipologia_Richiesta: id_tipologia_richiesta.value,
          data_Esportazione_Da: data_esportazione_da.value,
          data_Esportazione_A: data_esportazione_a.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
        },
        apiLink: globalApi.ESPORTAZIONI_LIST,
        itemName: "esportazioni_list",
      });
    };

    const colorStatus = ref([]);
    const getEsportazioniListNoStore = async (apiLink, params) => {
      await getList(apiLink, params).then((res) => {
        res.data.results.forEach((newArrEl, index) => {
          // confronto il colore sia nell'array attuale, sia in quello appena preso
          // se diverso, sostituisco l'array vecchio con quello nuovo
          if (newArrEl.colore != esportazioni_list.value[index].colore) {
            store.commit("spliceArrayFromName", "resultsesportazioni_list");
            store.commit("populateArrayFromName", {
              stateName: "resultsesportazioni_list",
              array: res.data.results,
            });
          }
        });
      });
    };

    const esportazioni_list = computed(() =>
      store.getters.getStateFromName("resultsesportazioni_list")
    );
    const pendingDoc = ref(false);
    const intervalCheckList = ref(null);

    intervalCheckList.value = setInterval(async () => checkPendingDoc(), 5000);

    const checkPendingDoc = async () => {
      // se id è 1 o 2 vuol dire che ci sono documenti in esportazione
      pendingDoc.value = esportazioni_list.value
        ? esportazioni_list.value.some(
            (element) =>
              element.id_stato_richiesta != 3 && element.id_stato_richiesta != 4
          )
        : false;

      //attiva l'intervallo solo se null (all'inizio)
      if (pendingDoc.value) {
        // se ci sono doc in espo, chiama la stessa api, con gli stessi parametri, ma senza aggiornare la lista
        await getEsportazioniListNoStore(globalApi.ESPORTAZIONI_LIST, {
          id_Tipologia_Richiesta: id_tipologia_richiesta.value,
          data_Esportazione_Da: data_esportazione_da.value,
          data_Esportazione_A: data_esportazione_a.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
        });
      } else {
        clearInterval(intervalCheckList.value);
        return;
      }
    };

    const resetFilters = () => {
      store.commit("resetFiltersEsportazioni");
      getEsportazioniList();
    };
    getEsportazioniList();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedesportazioni_list")
    );

    const searchEsportazioniList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipEsportazioni");
      getEsportazioniList();
    };

    onUnmounted(() => {
      clearInterval(intervalCheckList.value);
    });

    return {
      getEsportazioniList,
      resetFilters,
      searchEsportazioniList,
      pendingDoc,
      colorStatus,
    };
  },
});
</script>
